import React, { useEffect, useState } from "react";
import {
  Button, Modal, Col, Row, Image, Tabs, List,
  TabsProps, Avatar, Spin, Tooltip, Popover
} from "antd";
import "./modals.scss";
import { EyeFilled, InfoCircleOutlined, QrcodeOutlined, ExpandAltOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "common/hooks/redux";
import { RootState } from "store/index";
import { togglePreviewCollectionModal } from "store/collectionSuperNFT";
import { getCollectionDetail } from "services/collection.service";
import { calculateFileSize, assetPreview, assetTypeIcon } from "utils";
import AssetPreviewModal from "common/components/Modals/AssetPreviewModal";
import moment from "moment";
import setting from "assets/images/setting.svg";
import users from "assets/images/users.svg";
import { NFT_STATUS } from "../../constants";
import { useNavigate } from 'react-router-dom';

type PreviewType = {
  id?: number | undefined;
};

/**
 * @param {PreviewType} props
 */

const CollectionPreviewModal: React.FC<PreviewType> = (
  props: PreviewType
): React.ReactElement => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { previewCollectionModal, collectionDetail, collectionDetailLoading } = useAppSelector(
    (state: RootState) => state.collectionSuperNFT
  );
  const [preview, setPreview] = useState<any>(null);
  const { superNftDetail, saveSuperNFTLoading, superNftDetailLoading } =
    useAppSelector((state: RootState) => state.superNFT);

  const totalLength = (collectionDetail?.contributors?.length ?? 0) + (collectionDetail?.additionalAssets?.length ?? 0);
  const totalAssetLength = (collectionDetail?.additionalAssets?.length ?? 0);
  const totalNftList = collectionDetail?.nftCollectionItems;

  function toggleModal(visible: any = null) {
    dispatch(togglePreviewCollectionModal(visible));
  }
  function closePreview() {
    setPreview(null);
  }
  const listAllContributors = (nft: any) => {
    if (nft) {
      const newArray = [...(nft.contributors || []), ...(nft.additionalAssets || [])];
      return newArray;
    }
    return [];
  };

  const data = [
    {
      title: "Collection type",
      content: collectionDetail?.title,
    },
    // {
    //   title: 'File hash',
    //   content: '128gcnaksnlb'
    // },
    {
      title: "Contributors",
      content: totalLength,
    },
    {
      title: "Uploaded date",
      content: moment(collectionDetail?.created_at).format("MMM Do, YYYY"),
    },
    // {
    //   title: "Size",
    //   content: calculateFileSize(collectionDetail?.asset?.size),
    // },
    {
      title: "Price",
      content: `${collectionDetail?.price} $`,
    },
  ];

  function handlePreview(item: any) {
    setPreview(item.asset);
  }
  function handleMainPreview(item: any) {
    setPreview(item);
  }

  const detailPage = () => {
    navigate('/collection-detail/' + collectionDetail.id);
    toggleModal(false)
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Details`,
      icon: <InfoCircleOutlined />,
      children: (
        <List
          size="small"
          bordered
          dataSource={data}
          className="info-list"
          renderItem={(item) => (
            <List.Item>
              <div className="item-wrapper">
                <h3>{item.title}</h3>
                <span className="file-name">
                  {item.title == "File hash" && (
                    <Image
                      src={setting}
                      preview={false}
                      style={{ paddingRight: "8px" }}
                    />
                  )}
                  {item.title == "Contributors" && (
                    <Image
                      src={users}
                      preview={false}
                      style={{ paddingRight: "8px" }}
                    />
                  )}
                  {item.content}
                </span>
              </div>
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "2",
      label: `Assets (${totalAssetLength})`,
      icon: <QrcodeOutlined />,
      children: (
        <div className="details">
          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <hr style={{ flex: 1, marginRight: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
            <span style={{ margin: '0 1em', color: '#FFFFFF' }}>Main Asset</span>
            <hr style={{ flex: 1, marginLeft: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
          </div>
          <List
            size="small"
            bordered
            dataSource={[collectionDetail?.asset]}
            renderItem={(item: any) =>
              <List.Item className="details-list" actions={[<EyeFilled onClick={() => handleMainPreview(item)} />]}>
                <List.Item.Meta avatar={<Avatar src={require(`assets/images/filetypes/${assetTypeIcon(item?.mimetype)}`)} />} />
                <div className="item-wrapper">
                  <Tooltip title={item?.name}>
                    <span>{item?.name}</span>
                  </Tooltip>
                </div>
              </List.Item>
            }
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <hr style={{ flex: 1, marginRight: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
            <span style={{ margin: '0 1em', color: '#FFFFFF' }}>Additional Asset</span>
            <hr style={{ flex: 1, marginLeft: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
          </div> */}
          <List
            size="small"
            bordered
            dataSource={collectionDetail.additionalAssets}
            className="add-asset-list"
            renderItem={(item: any) => (
              <List.Item className="details-list" actions={[<EyeFilled onClick={() => handlePreview(item)} />]}>
                <List.Item.Meta
                  avatar={<Avatar src={require(`assets/images/filetypes/${assetTypeIcon(item.asset?.mimetype)}`)} />}
                />
                <div className="item-wrapper">
                  <Tooltip title={item.asset?.name}>
                    <span>{item.asset?.name}</span>
                  </Tooltip>
                </div>
              </List.Item>
            )}
          />
          <AssetPreviewModal asset={preview} closePreview={closePreview} />
        </div>
      ),
    },
    {
      key: "3",
      label: `NFTs (${collectionDetail?.nftCollectionItems?.length})`,
      children: (
        <div className="nft-list-wrap">
          <List
            dataSource={collectionDetail?.nftCollectionItems}
            className="nft-list"
            renderItem={(item: any) => (
              <List.Item
                key={item.superNft?.id}
              >
                <List.Item.Meta
                  title={
                    <div className="name-wrapper">
                      <span className="name">{item.superNft?.title}</span>
                      <span className="owner"> by {item.superNft?.artist_name}</span>
                    </div>
                  }
                />
                <div className="action-wrapper">
                  <div className="contributors">
                    <Popover
                      title="Contributors"
                      content={
                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                          <List
                            size="small"
                            dataSource={listAllContributors(item?.superNft)}
                            className="contributor-list"
                            renderItem={(item: any) => (
                              <List.Item>
                                <List.Item.Meta
                                  title={
                                    item?.contributor?.email
                                  }
                                />
                                {
                                  item.revenue_split ? (<div className="percent">
                                    {item.revenue_split}
                                  </div>) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', color: '#ffffff', fontSize: '12px' }}>
                                      <span>{item.contributor_revenue_split}</span>
                                      <span>{item.owner_revenue_split}</span>
                                    </div>
                                  )
                                }
                              </List.Item>
                            )}
                          />
                        </div>
                      }
                    >
                      <UserSwitchOutlined style={{ marginRight: '5px' }} />
                      <span>{listAllContributors(item?.superNft)?.length}</span>
                    </Popover>
                  </div>
                  <div className="date">{item.date}</div>
                </div>
              </List.Item>
            )}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (previewCollectionModal) {
      dispatch(getCollectionDetail(previewCollectionModal));
    }
  }, [previewCollectionModal]);

  return (
    <Modal
      open={!!previewCollectionModal}
      title="Collection view"
      width="auto"
      wrapClassName="collection-preview"
      onCancel={() => toggleModal(null)}
      style={{ maxWidth: "909px" }}
      footer={[
        <Tooltip title="coming soon">
          <Button key="back" disabled>
            Transfer ownership
          </Button>
        </Tooltip>,

        collectionDetail.status === NFT_STATUS.DRAFT ? (
          ""
        ) : (
          <Tooltip title="View more details">
            <Button key="view-blockchain" type="primary" onClick={detailPage}>
              {/* <ExpandAltOutlined /> */}
              View more details
            </Button>
          </Tooltip>
        )
      ]}
    >
      {collectionDetailLoading ? (<Spin size="large" style={{ display: 'block' }} />) : (
        <Row gutter={24}>
          <Col xl={10} lg={10} md={10} sm={10} xs={10}>
            <div className="cover-container">
              <Image
                // width="100%"
                max-height="450px"
                src={assetPreview(collectionDetail.coverImage?.url, collectionDetail.coverImage?.mimetype)}
                style={{ borderRadius: '8px' }}
                preview={false}
                className="cover-image"
              />
            </div>
          </Col>
          <Col xl={14} lg={14} md={14} sm={14} xs={14}>
            <div className="right-wrapper">
              <div className="title-wrap">
                <h1>{collectionDetail.title}</h1>
                <p>by <span style={{ color: "#c1fc49" }}>{collectionDetail.artist_name}</span></p>
              </div>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </Col>
        </Row>
      )}

    </Modal>
  );
};

export default CollectionPreviewModal;