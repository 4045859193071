import { Container } from "common/components";
import ConnectWalletModal from "../../../common/components/Modals/ConnectWalletModal";
import AddNewFileModal from "common/components/Modals/AddNewFileModal";
import { IComponent } from "common/interface";
import React, { useEffect } from "react";
import { Button, Image, Dropdown, Menu, notification } from "antd";
import { IconsMenu } from "./Menu";
import { toggleConnectWalletModal, toggleAddNewFileModal } from "store/global";
import { useAppDispatch, useAppSelector } from "common/hooks/redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import {
  PlusCircleFilled,
  ArrowLeftOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useAccount } from "wagmi";
import kwt from "assets/images/kwt-icon.svg";
import { addNotification } from "store/global";
import { SERVER_BASE_URL } from "common/constants";
import io from "socket.io-client";
import "./_header.scss";

/**
 * @description Header Component
 * @param {IComponent} props
 * @returns {React.ReactElement}
 */
const Header: React.FC<IComponent> = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const showModal = () => {
    dispatch(toggleConnectWalletModal(true));
  };
  const showAddFileModal = () => {
    dispatch(toggleAddNewFileModal(true));
  };
  const location = useLocation();
  const currentLocation = location.pathname;
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const socket = io(SERVER_BASE_URL, {
    query: { userId: user?.id },
  });

  useEffect(() => {
    socket.on("receiveNotification", (newNotification: any) => {
      console.log("New notification:", newNotification);
      dispatch(addNotification(newNotification));
      notification.info({
        message: newNotification.title,
        description: newNotification.message,
      });
    });
    return () => {
      socket.off("receiveNotification");
    };
  }, []);

  const data = [
    {
      chain: "Metamask",
      logo: "meta",
    },
    {
      chain: "Coinbase",
      logo: "coin",
    },
    {
      chain: "xdefi",
      logo: "xdefi",
    },
    {
      chain: "Math",
      logo: "math",
    },
    {
      chain: "TrustWallet",
      logo: "trust",
    },
    {
      chain: "Enjin",
      logo: "enjin",
    },
    {
      chain: "ZenGo",
      logo: "zen",
    },
    {
      chain: "Binance",
      logo: "binance",
    },
    {
      chain: "Xverse",
      logo: "xverse",
    },
  ];

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: "Single SuperNFT",
          onClick: () => navigate("/super-nft"),
        },
        {
          key: "2",
          label: "SuperNFT Collection",
          onClick: () => navigate("/super-nft/collection"),
          // disabled: true,
        },
      ]}
    />
  );

  return (
    <Container.HeaderContainer>
      <IconsMenu />
      <div className="head">
        {(() => {
          if (currentLocation === "/my-files") {
            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={showAddFileModal}
                  style={{
                    borderRadius: "32px",
                    background: "#C1FC49",
                    width: "157px",
                    height: "40px",
                    color: "#20222E",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PlusCircleFilled />
                  Add New File
                </Button>
                <AddNewFileModal />
                <ConnectWalletModal data={data} />
              </>
            );
          } else if (currentLocation.includes("/approval/")) {
            return (
              <Button
                onClick={handleBackNavigation}
                style={{
                  borderRadius: "20px",
                  background: "transparent",
                  // height: "40px",
                  color: "#C1FC49",
                  borderColor: "#C1FC49",
                }}
              >
                <ArrowLeftOutlined />
              </Button>
            );
          }
          else {
            return (
              <>
                {" "}
                {isConnected ? (
                  currentLocation.includes("/super-nft") ? '' : (

                    <Dropdown
                      overlayClassName="header-dropdown"
                      overlay={menu}
                      trigger={["click"]}
                      arrow
                      placement="bottomRight"
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          borderRadius: "32px",
                          background: "#C1FC49",
                          width: "171px",
                          height: "40px",
                          color: "#20222E",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PlusCircleFilled />
                        New SuperNFT
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  )

                  // <Button
                  // 	type="primary"
                  // 	htmlType="submit"
                  // 	onClick={() => navigate("/super-nft")}
                  // 	style={{
                  // 		borderRadius: "32px",
                  // 		background: "#C1FC49",
                  // 		width: "157px",
                  // 		height: "40px",
                  // 		color: "#20222E",
                  // 		display: "flex",
                  // 		alignItems: "center",
                  // 	}}
                  // >
                  // 	<PlusCircleFilled />
                  // 	New SuperNFT
                  // </Button>
                ) : (
                  <>
                    {/* <Button
                      onClick={showModal}
                      type="primary"
                      htmlType="submit"
                      style={{
                        borderRadius: "32px",
                        background: "#C1FC49",
                        width: "176px",
                        height: "40px",
                        color: "#20222E",
                      }}
                    >
                      <Image
                        src={kwt}
                        preview={false}
                        style={{ paddingRight: "5px" }}
                      />
                      Connect wallet
                    </Button> */}

                    <Dropdown
                      overlayClassName="header-dropdown"
                      overlay={menu}
                      trigger={["click"]}
                      arrow
                      placement="bottomRight"
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          borderRadius: "32px",
                          background: "#C1FC49",
                          width: "171px",
                          height: "40px",
                          color: "#20222E",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PlusCircleFilled />
                        New SuperNFT
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </>
                )
                }
                <ConnectWalletModal data={data} />
              </>
            );
          }
        })()}
      </div>
    </Container.HeaderContainer >
  );
};

export default React.memo(Header);
