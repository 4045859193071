import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getCollectionType = createAsyncThunk('get/collection/content-creator-type/all', async () => {
  try {
    const response = await axios.get('/collection/content-creator-type/all')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});


export const saveCollectionType = createAsyncThunk('save/collection/content-creator-type', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/collection/content-creator-type',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateCollectionType = createAsyncThunk('update/collection/content-creator-type/{id}', async (data: ContType, thunkAPI) => {
  try {
    const response = await axios.patch('/collection/content-creator-type/' + data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteCollectionType = createAsyncThunk('delete/collection/content-creator-type/{id}', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/collection/content-creator-type/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getCollectionTypeMini = createAsyncThunk('all/collection/content-creator-type/all/mini', async () => {
  try {
    const response = await axios.get('/collection/content-creator-type/all/mini')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});
