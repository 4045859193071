import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";
import { reArangeFilters } from "utils";
import { NFT_STATUS } from "common/constants";

export const getCollection = createAsyncThunk(
	"get/collection",
	async (id: any, thunkAPI) => {
		try {
			const response = await axios.get("/superNft/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getAllCollections = createAsyncThunk(
	"get/collections",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/superNft${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const saveCollection = createAsyncThunk(
	"save/collection",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/superNft", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const mintTheSuperNFTCollection = createAsyncThunk(
	"mint/superNft/collection",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/superNft/collection/mint", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateCollection = createAsyncThunk(
	"update/collection/previous",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch("/superNft/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateCollectionPreviousButton = createAsyncThunk(
	"update/superNft",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch("/superNft/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getCollectionDetail = createAsyncThunk(
	"get/collection/detail",
	async (id: any, thunkAPI) => {
		try {
			const response = await axios.get("/superNft/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const payForCollection = createAsyncThunk(
	"api/collection",
	async (body: any, thunkAPI) => {
		try {
			const response = await axios.post("/payment", body);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

// to get selected nfts in creating collection process
export const getCollectionNftList = createAsyncThunk(
	"get/collectionItem",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);

			const response = await axios.get("/collectionItem" + queryFilters);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

// to get all nft list in collection
export const getUserAllCollectionNfts = createAsyncThunk(
	"get/superNfts",
	async () => {
		try {
			const response = await axios.get(`/superNft?status=${NFT_STATUS.DRAFT}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const saveSelectedNftsInCollection = createAsyncThunk(
	"save/collectionItem",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/collectionItem", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateSelectedNftsInCollection = createAsyncThunk(
	"update/collectionItem/{id}",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch("/collectionItem/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

//delete collection
export const deleteCollection = createAsyncThunk(
	"delete/superNft",
	async (id: number | null, thunkAPI) => {
		try {
			const response = await axios.delete("/superNft/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const deleteSelectedNftsInCollection = createAsyncThunk(
	"delete/collectionItem/{id}",
	async (id: number | null, thunkAPI) => {
		try {
			const response = await axios.delete("/collectionItem/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getAllRevenueOfCollection = createAsyncThunk(
	"get/contributorRevenue",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/contributorRevenue${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getRecentCollectionsCount = createAsyncThunk(
	"/superNft/recent-counts",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(
				`/superNft/recent-counts${queryFilters}`
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getRecentCollectionList = createAsyncThunk(
	"/superNft/recent-list",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/superNft/recent-list${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getRecentCollectionCount = createAsyncThunk(
	"get/superNft/collection/recent/{count}",
	async (
		{ count, ...filters }: { count: number; [key: string]: any },
		thunkAPI
	) => {
		try {
			const queryFilters = new URLSearchParams(filters).toString(); // Convert other filters to query string
			const response = await axios.get(
				`superNft/recent/${count}?${queryFilters}`
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);
