import React, { useState, useEffect } from "react";
import { ReactReader } from "react-reader";
import { Button, Image, Card, Spin } from "antd";
import { AssetPreviewTypes } from "common/type";
import { assetPreview } from "utils";
import "./asset_preview.scss";
import epub from "assets/images/filetypes/epub.svg";
import EpubViewer from "../../components/EpubViewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { SERVER_BASE_URL } from "common/constants";

/**
 * @description Header Component
 * @param {IComponent} props
 * @returns {React.ReactElement}
 */

type PropsType = {
	asset: any;
};

const AssetPreview: React.FC<PropsType> = (
	props: PropsType
): React.ReactElement => {
	const { asset } = props;
	const [loading, setLoading] = useState(true);
	const [location, setLocation] = useState<any>(undefined);

	const handleLocationChanged = (newLocation: string) => {
		setLoading(false); // Assume the book is loaded once a location is set
		setLocation(newLocation);
	};

	return (
		<div className="asset-preview">
			{(() => {
				const type = asset?.mimetype?.split("/")[0];
				console.log("type", type);
				switch (type) {
					case "image":
						return (
							<Image
								width={"auto"}
								height="auto"
								className="img-height"
								src={assetPreview(asset?.url, asset?.mimetype)}
								preview={false}
							/>
						);
					case "video":
						return (
							<video
								width="100%"
								max-height="500px"
								controls
								src={asset?.url}
								style={{ maxHeight: "500px" }}
							>
								<source src={asset?.url} />
							</video>
						);
					case "application":
						if (asset?.mimetype) {
							// Handle .e.pub files
							return (
								<Card
									style={{ width: "100%", height: "400px", overflow: "auto" }}
								>
									{/* {loading && <Spin tip="Loading EPUB..." />} */}
									<div
										style={{
											width: "100%",
											height: "100%",
										}}
									>
										{" "}
										{asset?.mimetype === "application/epub+zip" ? (
											<EpubViewer
												fileUrl={`${SERVER_BASE_URL}/api/asset/preview?url=${encodeURIComponent(
													asset?.url
												)}`}
											/>
										) : (
											<DocViewer
												documents={[
													{
														uri: `${SERVER_BASE_URL}/api/asset/preview?url=${asset?.url}`,
													},
												]}
												pluginRenderers={DocViewerRenderers}
											/>
										)}
									</div>
								</Card>
							);
						}
						return <h1>No Preview available</h1>;
					case "audio":
						return (
							<audio controls className="audio audio_player" src={asset?.url}>
								<source src={asset?.url} />
							</audio>
						);
					case "text":
						return (
							<Image
								width={"100%"}
								height="300px"
								src={assetPreview(asset?.url, asset?.mimetype)}
								preview={false}
							/>
						);
					default:
						return <h1>{type}</h1>;
				}
			})()}
		</div>
	);
};

export default AssetPreview;
