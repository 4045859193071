import { AdditionalAssetsStoreType } from "store/type";

export const initGlobalState: AdditionalAssetsStoreType = {
	allAdditionalAssets: [],
	allCollAdditionalAssets: [],
	additionalContributions: [],
	additionalAssetDetail: {},
	getAdditionalAssetsLoading: false,
	getCollectionAdditionalAssetsLoading: false,
	saveAdditionalAssetsLoading: false,
	updateAdditionalAssetsLoading: false,
	deleteAdditionalAssetsLoading: false,
	additionalReminderLoading: null,
	additionalAssetDetailLoading: false,
	additionalContributionsLoading: false,
};
