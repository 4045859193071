import React, { useState, useEffect } from "react";
import {
	AppstoreOutlined,
	CopyOutlined,
	UsergroupAddOutlined,
	MoneyCollectOutlined,
	InteractionOutlined,
	SettingOutlined,
	BellOutlined,
	UserSwitchOutlined,
	TeamOutlined,
	FileSyncOutlined,
	FolderOutlined,
	TagsOutlined,
	UserOutlined,
	LogoutOutlined,
	FileOutlined,
	BarChartOutlined,
	TableOutlined,
	AppstoreAddOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
	Menu,
	Image,
	Avatar,
	Progress,
	Switch,
	List,
	Button,
	Tooltip,
	Badge,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { assetPreview } from "utils";
import "./side_menu.scss";
import { useAppSelector, useAppDispatch } from "common/hooks/redux";
import { toggleLogoutSession, toggleIsUserSideBarAdmin } from "store/auth";
import { toggleLogOutModal } from "store/auth";
import { RootState } from "store";
import { ROLES } from "common/constants/index";
import LogOutModal from "common/components/Modals/LogOutModal";
import logo from "assets/images/logo.svg";
import superNftlogo from "assets/images/nft-icon.svg";
import kwik from "assets/images/KwikTrust.svg";
import ProfileAvatar from "common/components/ProfileAvatar";

/**
 * SideMenu
 * @description 네비게이션 바
 * @param {} props
 * @returns {React.ReactElement}
 */

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
	icon: React.ReactNode,
	label: React.ReactNode,
	key: React.Key,
	disabled?: boolean,
	children?: MenuItem[],
	type?: "group"
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
		disabled,
	} as MenuItem;
}

const adminMenu: MenuProps["items"] = [
	getItem(<BarChartOutlined />, "Analysis", "/admin/analysis"),
	getItem(
		<UserSwitchOutlined />,
		"Contributor Type",
		"/admin/contributor-type"
	),
	getItem(<TeamOutlined />, "Contributor Role", "/admin/contributor-role"),
	getItem(<FileSyncOutlined />, "Institute Type", "/admin/institute-type"),
	getItem(<FolderOutlined />, "Asset Type", "/admin/asset-type"),
	getItem(<TagsOutlined />, "Asset Tags", "/admin/asset-tags"),
	getItem(<UserOutlined />, "Users", "/admin/user"),
	getItem(<AppstoreAddOutlined />, "Content Creator", "/admin/collection-type"),

];

const SideMenu: React.FC = (): React.ReactElement => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user, isUserSideBarAdmin } = useAppSelector(
		(state: RootState) => state.auth
	);
	const { notifications, getNotificationsLoading } = useAppSelector(
		(state: RootState) => state.global
	);
	const isAdmin = user?.roles?.includes(ROLES.ADMIN);
	const [loading, setLoading] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname?.includes("/admin/")) {
			dispatch(toggleIsUserSideBarAdmin(true));
		}
	}, []);

	const onClick: MenuProps["onClick"] = (e) => {
		navigate(e.key);
	};

	const logout = () => {
		dispatch(toggleLogOutModal(true));
	};

	const hasUnreadNotifications = notifications.some(
		(notification) => notification.is_read === false
	);

	const items: MenuProps["items"] = [
		getItem(<AppstoreOutlined />, "Dashboard", "/"),
		getItem(<CopyOutlined />, "My Files", "/my-files"),
		getItem(<FileOutlined />, "Recent Files", "/recent-files"),
		getItem(
			<Image
				src={superNftlogo}
				width="25px"
				preview={false}
				style={{ paddingRight: "10px" }}
			/>,
			"SuperNFT Library",
			"/nftlibrary"
		),
		getItem(
			<TableOutlined />,
			"Collection Library",
			"/collection-library"
		),
		getItem(<InteractionOutlined />, "Contributions", "/contributions"),
		getItem(<UsergroupAddOutlined />, "Contributors", "/contributors"),
		getItem(<MoneyCollectOutlined />, "Monetisation", "mone", true),
	];

	const data = [
		{
			link: "/#",
			name: "Logout",
			icon: <LogoutOutlined />,
		},
		{
			link: `${user?.contributor == null ? "/user-profile" : "/contributor-profile"
				}`,
			name: "User Profile",
			icon: <SettingOutlined />,
		},
		{
			link: "/profile-notifications",
			name: "Notifications",
			icon: hasUnreadNotifications ? (
				<Badge dot>
					<BellOutlined />{" "}
				</Badge>
			) : (
				<BellOutlined />
			),
		},
	];

	// const handleSwitchChange = (checked: any) => {
	// 	setCheckAdmin(checked);
	// 	if (isUserSideBarAdmin) {
	// 		navigate('/')
	// 	} else {
	// 		navigate('/admin/analysis')
	// 	}
	// };

	const handleSwitchChange = (checked: any) => {
		localStorage.setItem("isUserSideBarAdmin", JSON.stringify(checked));
		dispatch(toggleIsUserSideBarAdmin(checked));
		if (checked) {
			navigate("/admin/analysis");
		} else {
			navigate("/");
		}
	};

	const menuItems = isAdmin
		? isUserSideBarAdmin
			? [...adminMenu]
			: [...items]
		: [...items];

	useEffect(() => {
		// const savedState = localStorage.getItem("isUserSideBarAdmin");
		// console.log("savedState =>", savedState);
		// if (savedState !== null) {
		// 	toggleIsUserSideBarAdmin(JSON.parse(savedState));
		// }
	}, []);

	return (
		<div className="side-nav">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Image
					src={logo}
					preview={false}
					width="56%"
					onClick={
						isUserSideBarAdmin
							? () => navigate("/admin/analysis")
							: () => navigate("/")
					}
					style={{ cursor: "pointer" }}
				/>
				{isAdmin && (
					<Tooltip
						title={isUserSideBarAdmin ? "Switch to user" : "Switch to admin"}
					>
						<Switch
							size="small"
							checkedChildren="Admin"
							unCheckedChildren="User"
							checked={isUserSideBarAdmin}
							onChange={handleSwitchChange}
							loading={loading}
						/>
					</Tooltip>
				)}
			</div>
			<div className="profile-pic-cont">
				{user?.contributor == null ? (
					<Avatar
						onClick={() => navigate("/user-profile")}
						size={48}
						src={
							user?.profile_pic ? (
								assetPreview(
									user?.profile_pic?.url,
									user?.profile_pic?.mimetype
								)
							) : (
								<ProfileAvatar
									firstName={user?.first_name}
									lastName={user?.last_name}
									imageUrl={user?.profile_pic?.url}
								/>
							)
						}
						style={{ border: "1px solid #CAD8E5", cursor: "pointer" }}
					/>
				) : (
					<Avatar
						onClick={() => navigate("/contributor-profile")}
						size={48}
						src={
							user?.profile_pic ? (
								assetPreview(
									user?.profile_pic?.url,
									user?.profile_pic?.mimetype
								)
							) : (
								<ProfileAvatar
									firstName={user?.contributor?.first_name}
									lastName={user?.contributor?.last_name}
									imageUrl={user?.profile_pic?.url}
								/>
							)
						}
						style={{ border: "1px solid #CAD8E5", cursor: "pointer" }}
					/>
				)}
				<div className="info-inner">
					{user?.contributor == null ? (
						<div>
							<h2>{`${user?.first_name} ${user?.last_name}`}</h2>
							<span>
								KTX Credits:{" "}
								<b>{user?.credits ? Number(user.credits).toFixed(2) : 0}</b>
							</span>
						</div>
					) : (
						<div>
							<h2>{`${user?.contributor?.first_name} ${user?.contributor?.last_name}`}</h2>
							<span>
								KTX Credits:{" "}
								<b>{user?.credits ? Number(user.credits).toFixed(2) : 0}</b>
							</span>
						</div>
					)}
				</div>
			</div>
			<Menu
				onClick={onClick}
				style={{ width: 250, paddingBottom: "175px" }}
				defaultSelectedKeys={["1"]}
				defaultOpenKeys={["sub1"]}
				selectedKeys={[pathname]}
				mode="inline"
				items={menuItems}
			/>
			{/* <div className="bar-cont">
				<span className="upgrade">Upgrade</span>
				<Progress
					percent={30}
					format={(percent) => `${percent} GB remaining`}
					strokeColor="#C1FC49"
					trailColor="#CAD8E5"
				/>
			</div> */}
			<div className="info-wrapper">
				<List
					dataSource={data}
					grid={{ column: 3, gutter: 8 }}
					renderItem={(item) => (
						<List.Item>
							<Tooltip title={item.name}>
								<Button
									onClick={
										item.name == "Logout"
											? () => logout()
											: () => navigate(item.link)
									}
									icon={item.icon}
								/>
							</Tooltip>
						</List.Item>
					)}
				/>
				<div className="poweredby">
					<p>Powered by</p>
					<Image src={kwik} preview={false} width="110px" />
				</div>
				<LogOutModal />
			</div>
		</div>
	);
};

export default SideMenu;
