import Layout from "common/layouts";
import { ElementType, lazy, Suspense } from "react";

// * 추후 확장하기 위해 따로 빼둠.
export const LayoutComponent = (): React.ReactElement => {
  return <Layout />;
};

export const generateComponent = (Component: ElementType) => (props: any) => (
  <Suspense fallback={false}>
    <Component {...props} />
  </Suspense>
);

export const Dashboard = generateComponent(lazy(() => import("../pages/Dashboard")));
export const SignIn = generateComponent(lazy(() => import("../pages/SignIn")));
export const SignUp = generateComponent(lazy(() => import("../pages/SignUp")));
export const Verify = generateComponent(lazy(() => import("../pages/VerifyEmail/")));
export const Files = generateComponent(lazy(() => import("../pages/Files/")));
export const MyFiles = generateComponent(lazy(() => import("../pages/MyFiles")));
export const NftLibrary = generateComponent(lazy(() => import("../pages/NftLibrary/")));
export const Contributors = generateComponent(lazy(() => import("../pages/Contributors/")));
export const NewSuperNft = generateComponent(lazy(() => import("../pages/NewSuperNft/")));
export const Complete = generateComponent(lazy(() => import("../pages/NewSuperNft/Steps/Complete")));
export const DashboardNotData = generateComponent(lazy(() => import("../pages/DashboardNotData")));
export const UserProfile = generateComponent(lazy(() => import("../pages/UserProfile/")));
export const ContributorsType = generateComponent(lazy(() => import("../pages/AdminPanel/ContributorType")));
export const ContributorsRole = generateComponent(lazy(() => import("../pages/AdminPanel/ContributorRole")));
export const InstituteType = generateComponent(lazy(() => import("../pages/AdminPanel/InstituteType")));
export const AssetType = generateComponent(lazy(() => import("../pages/AdminPanel/AssetType")));
export const AssetTags = generateComponent(lazy(() => import("../pages/AdminPanel/TagsForAssets")));
export const CreateUser = generateComponent(lazy(() => import("../pages/AdminPanel/CreateUser")));
export const CollectionType = generateComponent(lazy(() => import("../pages/AdminPanel/CollectionType")));
export const Reminder = generateComponent(lazy(() => import("../pages/Reminder")));
export const ContributorProfile = generateComponent(lazy(() => import("../pages/ContributorProfile")));
export const NftPreviewDetail = generateComponent(lazy(() => import("../pages/NftDetailPreview")));
export const RecentFiles = generateComponent(lazy(() => import("../pages/RecentFiles/")));
export const IdentityVerification = generateComponent(lazy(() => import("../pages/IdentityVerification/")));
export const AllNotifications = generateComponent(lazy(() => import("../pages/AllNotifications/")));
export const AdditionalReminder = generateComponent(lazy(() => import("../pages/AdditionalReminder/")));
export const Contributions = generateComponent(lazy(() => import("../pages/Contributions/")));
export const AdminDashboard = generateComponent(lazy(() => import("../pages/AdminDashboard")));
export const NftCollections = generateComponent(lazy(() => import("../pages/SuperNftCollection")));
export const CollectionLibrary = generateComponent(lazy(() => import("../pages/CollectionLibrary/")));
export const CollectionDetail = generateComponent(lazy(() => import("../pages/CollectionDetail/")));
export const CollectionMinted = generateComponent(lazy(() => import("../pages/SuperNftCollection/steps/CollectionMinted")));
export const CollectionInvite = generateComponent(lazy(() => import("../pages/CollectionInvite/")));
