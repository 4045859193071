import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initGlobalState } from "./default";
import { PendingTransactionType, NotificationType } from "./../type";
import {
	getUserNotificationsList,
	markReadNotification,
} from "services/createUser.service";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {
		toggleConnectWalletModal: (state, action: PayloadAction<boolean>) => {
			state.connectWalletModal = action.payload;
		},
		toggleUploadAssetsModal: (
			state,
			action: PayloadAction<boolean | string>
		) => {
			state.uploadAssetsModal = action.payload;
		},
		toggleNftPreviewModal: (state, action: PayloadAction<any>) => {
			state.nftPreviewModal = action.payload;
		},
		toggleAddContributorModal: (state, action: PayloadAction<boolean>) => {
			state.addContributorModal = action.payload;
		},
		toggleAddNewAssetModal: (state, action: PayloadAction<boolean>) => {
			state.addnewAssetModal = action.payload;
		},
		toggleUploadProfileModal: (state, action: PayloadAction<boolean>) => {
			state.uploadProfileModal = action.payload;
		},
		toggleAddNewFileModal: (state, action: PayloadAction<boolean>) => {
			state.addNewFileModal = action.payload;
		},
		toggleActionCompleteModal: (state, action: PayloadAction<boolean>) => {
			state.actionCompleteModal = action.payload;
		},
		toggleCommonFormModal: (state, action: PayloadAction<boolean | number>) => {
			state.isCommonFormModal = action.payload;
		},
		toggleCommonFormSubmitLoading: (state, action: PayloadAction<boolean>) => {
			state.commonFormSubmitLoading = action.payload;
		},
		toggleRejectingReasonModal: (state, action: PayloadAction<boolean>) => {
			state.rejectingReasonModal = action.payload;
		},
		toggleDeleteModal: (state, action: PayloadAction<boolean>) => {
			state.DeleteModal = action.payload;
		},
		toggleCreateUserModal: (state, action: PayloadAction<boolean>) => {
			state.createUserModal = action.payload;
		},
		toggleAssetPreviewModal: (state, action: PayloadAction<boolean>) => {
			state.assetPreviewModal = action.payload;
		},
		togglePolicyDrawer: (state, action: PayloadAction<boolean>) => {
			state.policyDrawer = action.payload;
		},
		togglePrivacyDrawer: (state, action: PayloadAction<boolean>) => {
			state.privacyDrawer = action.payload;
		},
		togglePendingPayment: (state, action: PayloadAction<boolean>) => {
			state.paymentPendingModal = action.payload;
		},
		togglePaymentModal: (state, action: PayloadAction<boolean>) => {
			state.paymentModal = action.payload;
		},
		toggleSwitchNetworkModal: (state, action: PayloadAction<boolean>) => {
			state.switchNetworkModal = action.payload;
		},
		toggleBuyCreditsModal: (state, action: PayloadAction<boolean>) => {
			state.buyCreditsModal = action.payload;
		},
		toggleCloneNftModal: (state, action: PayloadAction<any>) => {
			state.nftCloneId = action.payload;
		},
		toggleCollectionWarningModal: (state, action: PayloadAction<boolean>) => {
			state.collectionWarningModal = action.payload;
		},

		setTransactionLoading: (
			state,
			action: PayloadAction<PendingTransactionType>
		) => {
			state.pendingTransaction = {
				...state.pendingTransaction,
				...action.payload,
			};
		},

		resetTransactionLoading: (state) => {
			state.pendingTransaction = initGlobalState.pendingTransaction;
		},

		addNotification: (state, action: PayloadAction<NotificationType>) => {
			state.notifications.unshift(action.payload);
		},
	},
	extraReducers: (builder) => {
		builder
			// Sign-in
			.addCase(getUserNotificationsList.pending, (state) => {
				state.getNotificationsLoading = true;
			})
			.addCase(getUserNotificationsList.fulfilled, (state, action) => {
				state.getNotificationsLoading = false;
				state.notifications = action.payload?.data || [];
			})
			.addCase(getUserNotificationsList.rejected, (state, action) => {
				state.getNotificationsLoading = false;
			})

			.addCase(markReadNotification.fulfilled, (state, action) => {
				let ntfIndex = state.notifications?.findIndex(
					(item) => item.id == action.payload
				);
				if (ntfIndex > -1) {
					state.notifications[ntfIndex].is_read = true;
				}
			});
	},
});

export const {
	toggleConnectWalletModal,
	toggleUploadAssetsModal,
	toggleNftPreviewModal,
	toggleAddContributorModal,
	toggleAddNewAssetModal,
	toggleUploadProfileModal,
	toggleAddNewFileModal,
	toggleActionCompleteModal,
	toggleCommonFormModal,
	toggleDeleteModal,
	toggleRejectingReasonModal,
	toggleCommonFormSubmitLoading,
	toggleCreateUserModal,
	toggleAssetPreviewModal,
	togglePolicyDrawer,
	togglePendingPayment,
	togglePaymentModal,
	toggleSwitchNetworkModal,
	toggleBuyCreditsModal,
	togglePrivacyDrawer,
	setTransactionLoading,
	resetTransactionLoading,
	addNotification,
	toggleCloneNftModal,
	toggleCollectionWarningModal,
} = globalSlice.actions;

export default globalSlice.reducer;
