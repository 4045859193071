import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initCollectionSuperNFTState } from "./default";
import {
	getAllCollections,
	saveCollection,
	getCollection,
	updateCollection,
	updateCollectionPreviousButton,
	getCollectionDetail,
	getCollectionNftList,
	getUserAllCollectionNfts,
	mintTheSuperNFTCollection,
	saveSelectedNftsInCollection,
	updateSelectedNftsInCollection,
	deleteSelectedNftsInCollection,
	getAllRevenueOfCollection,
	getRecentCollectionsCount,
	deleteCollection,
	getRecentCollectionList,
	getRecentCollectionCount,
} from "services/collection.service";
import { SuperNFTType } from "common/type";
import { message } from "antd";

const collectionSuperNFTSlice = createSlice({
	name: "collectionSuperNFT",
	initialState: initCollectionSuperNFTState,
	reducers: {
		toggleCollectionStep: (state, action: PayloadAction<number>) => {
			state.collectionStep = action.payload;
		},
		toggleSelectNftModal: (state, action: PayloadAction<boolean>) => {
			state.selectSuperNftModal = action.payload;
		},
		resetSuperNftCollectionForm: (state) => {
			state.formCollection = { ...initCollectionSuperNFTState.formCollection };
		},
		togglePreviewCollectionModal: (state, action: PayloadAction<any>) => {
			state.previewCollectionModal = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			// List-Collection
			.addCase(getAllCollections.pending, (state) => {
				state.getCollectionListLoading = true;
			})
			.addCase(getAllCollections.fulfilled, (state, action) => {
				state.getCollectionListLoading = false;
				state.allCollections = action.payload;
			})
			.addCase(getAllCollections.rejected, (state, action) => {
				state.getCollectionListLoading = false;
			})

			.addCase(getCollection.pending, (state) => {
				state.getCollectionLoading = true;
			})
			.addCase(getCollection.fulfilled, (state, action) => {
				state.getCollectionLoading = false;
				state.formCollection = action.payload;
			})
			.addCase(getCollection.rejected, (state, action) => {
				state.getCollectionLoading = false;
			})

			// Save-Collection
			.addCase(saveCollection.pending, (state) => {
				state.saveCollectionLoading = true;
			})
			.addCase(
				saveCollection.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					const collection: SuperNFTType = action.payload;
					state.saveCollectionLoading = false;
					state.formCollection = collection;
					// state.allCollections.unshift(testD);
				}
			)
			.addCase(saveCollection.rejected, (state, action) => {
				state.saveCollectionLoading = false;
			})

			// Update-Collection
			.addCase(updateCollection.pending, (state) => {
				state.saveCollectionLoading = true;
			})
			.addCase(
				updateCollection.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					state.saveCollectionLoading = false;
					message.success("Record updated successfully");
					state.formCollection = action.payload;
				}
			)
			.addCase(updateCollection.rejected, (state, action) => {
				state.saveCollectionLoading = false;
			})

			// Delete-Collection
			.addCase(deleteCollection.pending, (state) => {
				state.deleteCollectionLoading = true;
			})
			.addCase(deleteCollection.fulfilled, (state, action) => {
				state.deleteCollectionLoading = false;
				message.success("Record deleted successfully");
				state.allCollections = state.allCollections.filter(
					(item) => item.id !== action.meta.arg
				);
				// state.getNFTCount = state.getNFTCount.filter(
				// 	(item) => item.id !== action.meta.arg
				// );
			})
			.addCase(deleteCollection.rejected, (state, action) => {
				state.deleteCollectionLoading = false;
			})

			// Update-Collection-throught-previous-button
			.addCase(updateCollectionPreviousButton.pending, (state) => {
				state.saveCollectionPreviousLoading = true;
			})
			.addCase(
				updateCollectionPreviousButton.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					state.saveCollectionPreviousLoading = false;
					message.success("Record updated successfully");
					state.formCollection = action.payload;
				}
			)
			.addCase(updateCollectionPreviousButton.rejected, (state, action) => {
				state.saveCollectionPreviousLoading = false;
			})

			//get-collection-detail
			.addCase(getCollectionDetail.pending, (state) => {
				state.collectionDetailLoading = true;
			})
			.addCase(getCollectionDetail.fulfilled, (state, action) => {
				state.collectionDetailLoading = false;
				state.collectionDetail = action.payload;
			})
			.addCase(getCollectionDetail.rejected, (state, action) => {
				state.collectionDetailLoading = false;
			})

			// Collection-nft-list
			.addCase(getCollectionNftList.pending, (state) => {
				state.collectionNftListLoading = true;
			})
			.addCase(getCollectionNftList.fulfilled, (state, action) => {
				state.getCollectionListLoading = false;
				state.collectionNftList = action.payload;
			})
			.addCase(getCollectionNftList.rejected, (state, action) => {
				state.collectionNftListLoading = false;
			})

			// List-SuperNFTs
			.addCase(getUserAllCollectionNfts.pending, (state) => {
				state.collectionNftListLoading = true;
			})
			.addCase(getUserAllCollectionNfts.fulfilled, (state, action) => {
				state.collectionNftListLoading = false;
				state.userCollectionNFTList = action.payload;
			})
			.addCase(getUserAllCollectionNfts.rejected, (state, action) => {
				state.userCollectionNFTListLoading = false;
			})

			// Save-Selected-nfts-in-colledtion
			.addCase(saveSelectedNftsInCollection.pending, (state) => {
				state.saveNftInCollectionLoading = true;
			})
			.addCase(
				saveSelectedNftsInCollection.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					const selectedNft: any = action.payload;
					state.saveNftInCollectionLoading = false;
					state.collectionNftList.push(selectedNft);
					// state.allCollections.unshift(testD);
				}
			)
			.addCase(saveSelectedNftsInCollection.rejected, (state, action) => {
				state.saveNftInCollectionLoading = false;
			})

			// Update-Selected-nfts-in-colledtion
			.addCase(updateSelectedNftsInCollection.pending, (state, action: any) => {
				state.updateNftInCollectionLoading = action.meta?.arg?.id;
			})
			.addCase(
				updateSelectedNftsInCollection.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.updateNftInCollectionLoading = false;
					message.success("Record updated successfully");
					const index = state.collectionNftList.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.collectionNftList[index] = action.payload;
					}
				}
			)
			.addCase(updateSelectedNftsInCollection.rejected, (state, action) => {
				state.updateNftInCollectionLoading = false;
			})

			// Mint-SuperNFT-collection
			.addCase(mintTheSuperNFTCollection.pending, (state) => {
				state.saveCollectionLoading = true;
			})
			.addCase(
				mintTheSuperNFTCollection.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					state.saveCollectionLoading = false;
					message.success("Record updated successfully");
					state.formCollection = action.payload;
				}
			)
			.addCase(mintTheSuperNFTCollection.rejected, (state, action) => {
				state.saveCollectionLoading = false;
			})

			// Delete-Selected-nfts-in-colledtion

			.addCase(deleteSelectedNftsInCollection.pending, (state) => {
				state.deleteNftInCollectionLoading = true;
			})
			.addCase(deleteSelectedNftsInCollection.fulfilled, (state, action) => {
				state.deleteNftInCollectionLoading = false;
				message.success("Record deleted successfully");
				state.collectionNftList = state.collectionNftList.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteSelectedNftsInCollection.rejected, (state, action) => {
				state.deleteNftInCollectionLoading = false;
			})

			// List-collection-revenues
			.addCase(getAllRevenueOfCollection.pending, (state) => {
				state.getAllRevenueOfCollectionLoading = true;
			})
			.addCase(getAllRevenueOfCollection.fulfilled, (state, action) => {
				state.getAllRevenueOfCollectionLoading = false;
				state.allRevenueOfCollection = action.payload;
			})
			.addCase(getAllRevenueOfCollection.rejected, (state, action) => {
				state.getAllRevenueOfCollectionLoading = false;
			})

			// Get recent collections count
			.addCase(getRecentCollectionsCount.pending, (state) => {
				state.recentCollectionsCountLoading = true;
			})
			.addCase(getRecentCollectionsCount.fulfilled, (state, action) => {
				state.recentCollectionsCountLoading = false;
				state.recentCollectionCounts = action.payload;
			})
			.addCase(getRecentCollectionsCount.rejected, (state, action) => {
				state.recentCollectionsCountLoading = false;
			})
		
			// Get Collection recent list
			.addCase(getRecentCollectionList.pending, (state) => {
				state.collectionRecentListLoading = true;
			})
			.addCase(getRecentCollectionList.fulfilled, (state, action) => {
				state.collectionRecentListLoading = false;
				state.collectionRecentList = action.payload;
			})
			.addCase(getRecentCollectionList.rejected, (state, action) => {
				state.collectionRecentListLoading = false;
			})
			
			// Get Collections Count
			.addCase(getRecentCollectionCount.pending, (state) => {
				state.recentCollectionCountLoading = true;
			})
			.addCase(getRecentCollectionCount.fulfilled, (state, action) => {
				state.recentCollectionCountLoading = false;
				state.recentCollectionCount = action.payload;
			})
			.addCase(getRecentCollectionCount.rejected, (state, action) => {
				state.recentCollectionCountLoading = false;
			})
	},
});

export const {
	toggleCollectionStep,
	toggleSelectNftModal,
	resetSuperNftCollectionForm,
	togglePreviewCollectionModal,
} = collectionSuperNFTSlice.actions;

export default collectionSuperNFTSlice.reducer;
