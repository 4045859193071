import UnProtectedLayout from "common/layouts/unprotected";
import { I18nCommandEnum } from "core";
import { NonIndexRouteObject } from "react-router-dom";
import {
	Dashboard,
	LayoutComponent,
	SignIn,
	SignUp,
	Verify,
	Files,
	NftLibrary,
	Contributors,
	NewSuperNft,
	Complete,
	DashboardNotData,
	UserProfile,
	ContributorsType,
	ContributorsRole,
	InstituteType,
	AssetType,
	MyFiles,
	AssetTags,
	CreateUser,
	Reminder,
	ContributorProfile,
	NftPreviewDetail,
	RecentFiles,
	IdentityVerification,
	AllNotifications,
	AdditionalReminder,
	Contributions,
	AdminDashboard,
	NftCollections,
	CollectionLibrary,
	CollectionDetail,
	CollectionType,
	CollectionMinted,
	CollectionInvite,
} from "./item";

export enum RoutePath {
	DASHBOARD = "/",
	USER_LIST = "/admin/user",
	USER_DETAIL = "/userDetail",
	DESIGN_LIST = "/design",
	DESIGN_DETAIL = "/designDetail",
	CONTENTS_DETAIL = "/contentsDetail",
	SIGN_IN = "/signIn",
	SIGN_UP = "/signUp",
	VERIFY = "/verify/:email",
	FILES = "/files",
	NFT_LIBRARY = "/nftlibrary",
	CONTRIBUTORS = "/contributors",
	NEW_SUPER_NFT = "/super-nft",
	EDIT_SUPER_NFT = "/super-nft/:id",
	COMPLETE = "/complete/:id",
	DASHBOARD_NO_DATA = "/dashboard-no-data",
	USER_PROFILE = "/user-profile",
	MY_FILES = "/my-files",
	CONTRIBUTORS_TYPE = "/admin/contributor-type",
	CONTRIBUTORS_ROLE = "/admin/contributor-role",
	INSTITUTE_TYPE = "/admin/institute-type",
	ASSET_TYPE = "/admin/asset-type",
	ASSET_TAGS = "/admin/asset-tags",
	CREATE_USER = "/admin/user",
	COLLECTION_TYPE = "/admin/collection-type",
	REMINDER = "/superNFT/approval/:revenueId",
	CONT_PROFILE = "/contributor-profile",
	NFT_PREVIEW_DETAIL = "/nft-preview-detail/:id",
	RECENT_FILES = "/recent-files",
	IDENTITY_VERIFICATION = "/profile-identity-verification",
	ALL_NOTIFICATIONS = "/profile-notifications",
	ADDITIONAL_REMINDER = "/superNFT/asset/approval/:AuditionassetID",
	CONTRIBUTIONS = "/contributions",
	ADMIN_DASHBOARD = "/admin/analysis",
	NEW_SUPER_NFT_COLLECTIONS = "/super-nft/collection",
	EDIT_SUPER_NFT_COLLECTIONS = "/super-nft/collection/:id",
	COLLECTION_LIBRARY = "/collection-library",
	COLLECTION_DETAIL = "/collection-detail/:id",
	COLLECTION_COMPLETE = "/collection/complete/:id",
	COLLECTION_INVITE = "/superNFT/collection/approval/:revenueId",
}

export interface IRoute extends NonIndexRouteObject {
	i18nKey: I18nCommandEnum | undefined;
	children: any;
	adminRoute?: boolean;
}

const routes: IRoute[] = [
	{
		path: RoutePath.DASHBOARD,
		i18nKey: I18nCommandEnum.DASHBOARD,
		element: <LayoutComponent />,
		children: [{ element: <Dashboard />, index: true }],
	},
	{
		path: RoutePath.FILES,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <Files />, index: true }],
	},
	{
		path: RoutePath.MY_FILES,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <MyFiles />, index: true }],
	},
	{
		path: RoutePath.RECENT_FILES,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <RecentFiles />, index: true }],
	},
	{
		path: RoutePath.DASHBOARD_NO_DATA,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <DashboardNotData />, index: true }],
	},
	{
		path: RoutePath.ADMIN_DASHBOARD,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <AdminDashboard />, index: true }],
	},
	{
		path: RoutePath.NFT_LIBRARY,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <NftLibrary />, index: true }],
	},
	{
		path: RoutePath.CONTRIBUTORS,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <Contributors />, index: true }],
	},
	{
		path: RoutePath.CONTRIBUTORS_TYPE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <ContributorsType />, index: true }],
	},
	{
		path: RoutePath.CONTRIBUTORS_ROLE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <ContributorsRole />, index: true }],
	},
	{
		path: RoutePath.INSTITUTE_TYPE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <InstituteType />, index: true }],
	},
	{
		path: RoutePath.ASSET_TYPE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <AssetType />, index: true }],
	},
	{
		path: RoutePath.ASSET_TAGS,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <AssetTags />, index: true }],
	},
	{
		path: RoutePath.CREATE_USER,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <CreateUser />, index: true }],
	},
	{
		path: RoutePath.COLLECTION_TYPE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <CollectionType />, index: true }],
	},
	{
		path: RoutePath.NEW_SUPER_NFT,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <NewSuperNft />, index: true }],
	},
	{
		path: RoutePath.EDIT_SUPER_NFT,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <NewSuperNft />, index: true }],
	},
	{
		path: RoutePath.COMPLETE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <Complete />, index: true }],
	},
	{
		path: RoutePath.USER_PROFILE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <UserProfile />, index: true }],
	},
	{
		path: RoutePath.SIGN_IN,
		i18nKey: undefined,
		element: <UnProtectedLayout />,
		children: [{ element: <SignIn />, index: true }],
	},
	{
		path: RoutePath.SIGN_UP,
		i18nKey: undefined,
		element: <UnProtectedLayout />,
		children: [{ element: <SignUp />, index: true }],
	},
	{
		path: RoutePath.VERIFY,
		i18nKey: undefined,
		element: <UnProtectedLayout />,
		children: [{ element: <Verify />, index: true }],
	},
	{
		path: RoutePath.REMINDER,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <Reminder />, index: true }],
	},
	{
		path: RoutePath.ADDITIONAL_REMINDER,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <AdditionalReminder />, index: true }],
	},
	{
		path: RoutePath.CONT_PROFILE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <ContributorProfile />, index: true }],
	},
	{
		path: RoutePath.NFT_PREVIEW_DETAIL,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <NftPreviewDetail />, index: true }],
	},
	{
		path: RoutePath.IDENTITY_VERIFICATION,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <IdentityVerification />, index: true }],
	},
	{
		path: RoutePath.ALL_NOTIFICATIONS,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <AllNotifications />, index: true }],
	},
	{
		path: RoutePath.CONTRIBUTIONS,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <Contributions />, index: true }],
	},
	{
		path: RoutePath.NEW_SUPER_NFT_COLLECTIONS,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <NftCollections />, index: true }],
	},
	{
		path: RoutePath.EDIT_SUPER_NFT_COLLECTIONS,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <NftCollections />, index: true }],
	},
	{
		path: RoutePath.COLLECTION_LIBRARY,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <CollectionLibrary />, index: true }],
	},
	{
		path: RoutePath.COLLECTION_DETAIL,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <CollectionDetail />, index: true }],
	},
	{
		path: RoutePath.COLLECTION_COMPLETE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <CollectionMinted />, index: true }],
	},
	{
		path: RoutePath.COLLECTION_INVITE,
		i18nKey: undefined,
		element: <LayoutComponent />,
		children: [{ element: <CollectionInvite />, index: true }],
	},
];

export default routes;
