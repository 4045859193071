import { GlobalStoreType } from "store/type";

export const initGlobalState: GlobalStoreType = {
	connectWalletModal: false,
	uploadAssetsModal: false,
	nftPreviewModal: null,
	addContributorModal: false,
	addnewAssetModal: false,
	uploadProfileModal: false,
	addNewFileModal: false,
	actionCompleteModal: false,
	nftCloneId: null,
	isCommonFormModal: false,
	commonFormSubmitLoading: false,
	DeleteModal: false,
	rejectingReasonModal: false,
	createUserModal: false,
	assetPreviewModal: false,
	policyDrawer: false,
	privacyDrawer: false,
	paymentPendingModal: false,
	paymentModal: false,
	switchNetworkModal: false,
	buyCreditsModal: false,
	pendingTransaction: {
		loading: false,
		hash: undefined,
		msg: undefined,
	},
	notifications: [],
	getNotificationsLoading: false,
	collectionWarningModal: false,
};
