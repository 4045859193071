import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getCollectionType,
	saveCollectionType,
	deleteCollectionType,
	updateCollectionType,
	getCollectionTypeMini,
} from "services/collectionType.service";
import { initGlobalState } from "./default";
import { ContType } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// List-contributor-types
			.addCase(getCollectionType.pending, (state) => {
				state.getCollectionTypeLoading = true;
			})
			.addCase(getCollectionType.fulfilled, (state, action) => {
				state.getCollectionTypeLoading = false;
				state.allCollectionTypes = action.payload;
			})
			.addCase(getCollectionType.rejected, (state, action) => {
				state.getCollectionTypeLoading = false;
			})

			// Save-contributor-type
			.addCase(saveCollectionType.pending, (state) => {
				// state.getCollectionTypeLoading = true;
			})
			.addCase(
				saveCollectionType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					const testD: ContType = action.payload;
					state.allCollectionTypes.unshift(testD);
				}
			)
			.addCase(saveCollectionType.rejected, (state, action) => {
				// state.getCollectionTypeLoading = false;
			})

			// Update-contributor-type
			.addCase(updateCollectionType.pending, (state) => {
				state.updateCollectionTypeLoading = true;
			})
			.addCase(
				updateCollectionType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					state.updateCollectionTypeLoading = false;
					message.success("Record updated successfully");
					const index = state.allCollectionTypes.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.allCollectionTypes[index] = action.payload;
					}
				}
			)
			.addCase(updateCollectionType.rejected, (state, action) => {
				state.updateCollectionTypeLoading = false;
			})

			// Delete-contributor-type
			.addCase(deleteCollectionType.pending, (state) => {
				state.deleteCollectionTypeLoading = true;
			})
			.addCase(deleteCollectionType.fulfilled, (state, action) => {
				state.deleteCollectionTypeLoading = false;
				message.success("Record deleted successfully");
				state.allCollectionTypes = state.allCollectionTypes.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteCollectionType.rejected, (state, action) => {
				state.deleteCollectionTypeLoading = false;
			})

			// Get All asset types mini
			.addCase(getCollectionTypeMini.fulfilled, (state, action) => {
				state.allCollectionTypesMini = action.payload;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
