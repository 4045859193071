import { ContributionTypesTypeStoreType } from "store/type";

export const initGlobalState: ContributionTypesTypeStoreType = {
  allCollectionTypes: [],
  allCollectionTypesMini: [],
  getCollectionTypeLoading: false,
  updateCollectionTypeLoading: false,
  saveCollectionTypeLoading: false,
  deleteCollectionTypeLoading: false
};
