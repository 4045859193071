import React, { useRef, useEffect, useState } from "react";
import { Button } from "antd";
import ePub from "epubjs";
import "./epub_viewer.scss";

const EpubViewer = ({ fileUrl }) => {
  const viewerRef = useRef(null);
  const [rendition, setRendition] = useState(null);

  useEffect(() => {
    const book = ePub(fileUrl);
    const rendition = book.renderTo(viewerRef.current, {
      width: "100%",
      height: "100%",
      method: "default",
            spread: "both", // Ensure two-page spread
      flow: "auto", // This will scroll the content, instead of pagination
    });
  
    rendition.display();
  
    setRendition(rendition);
    rendition.themes.register("custom-theme", {
      "body": {
        "column-count": "2", // Force two columns for two-page spread
        "column-gap": "5px", // Space between the columns
        "margin": "0",
        "padding": "0",
        "overflow": "hidden",
      },
    });
    rendition.themes.select("custom-theme");
    return () => {
      // Destroy rendition and book on unmount
      if (rendition) {
        rendition.destroy();
      }
      book.destroy();
    };
  }, [fileUrl]);
  

  return (
    <div>
      <div
        id="viewer"
        ref={viewerRef}
        style={{        
          width: "100%",
          height: "100vh", // Full viewport height
          // maxWidth: "500px", // Ensure sufficient width for two pages
          margin: "0 auto",
          border: "1px solid #ddd",
          overflow: "hidden",
          marginBottom: '10px',
        }}
      ></div>
      <Button type="primary" style={{float: 'right'}} onClick={() => rendition.next()}>Next</Button>
      <Button type="primary" style={{marginRight: '10px', float: 'right'}} onClick={() => rendition.prev()}>Previous</Button>
    </div>
  );
};

export default EpubViewer;
