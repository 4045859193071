
import React from "react";
import { Button, Image } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './menu.scss'
import { useAccount } from 'wagmi'
import { getCenterShort, getHeadingText } from "utils"
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { toggleConnectWalletModal } from "store/global"
import { useNavigate, useLocation } from "react-router-dom";
import logo from "assets/images/logo.svg";
import kwt from "assets/images/kwt-icon.svg";
import { RootState } from "store";


/**
 * @description Header Icons Component
 * @param {IconMenuType} props
 * @returns {React.ReactElement}
 */
const IconMenu: React.FC = (
): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname;

  const { user, isUserSideBarAdmin } = useAppSelector(
    (state: RootState) => state.auth
  );

  const { address, isConnected } = useAccount()

  const headingText = getHeadingText(currentLocation);

  return (
    <div className="header-left">
      {currentLocation.includes("/super-nft") ?
        <div className="logo">
          <Image
            src={logo}
            preview={false}
            width="180px"
            onClick={
              isUserSideBarAdmin
                ? () => navigate("/admin/analysis")
                : () => navigate("/")
            }
            style={{ cursor: "pointer" }}
          />

        </div>
        : <h1 style={{
          color: '#FFF', fontFamily: 'Poppins', fontSize: '24px', fontWeight: 700, cursor: 'pointer',
        }}>{headingText}</h1>}
      <Button className={isConnected ? 'status-btn active' : 'status-btn'} htmlType="submit" onClick={() => dispatch(toggleConnectWalletModal(true))}>
        {isConnected ?
          <div>
            <span className="connected">Connected</span>
            <span className="meta">{getCenterShort(address)}</span>
            <EditOutlined style={{ fontSize: '16px' }} />
          </div> :
          <div>
            <Image
              src={kwt}
              preview={false}
              style={{ paddingRight: "6px" }}
            />
            Connect wallet
          </div>

        }

      </Button>
    </div>
  );
};

export default React.memo(IconMenu);
