import { configureStore } from '@reduxjs/toolkit'
import globalSlice from  "./global/index"
import authSlice from  "./auth/index"
import contributorSlice from "./contributors/index"
import assetTypesSlice from "./assetTypes/index"
import assetTagsSlice from "./assetTags/index"
import instituteStoreSlice from "./instituteType/index"
import contributorTypeSlice from "./contributorsType/index"
import contributorRoleSlice from "./contributorsRole/index"
import collectionTypeSlice from "./collectionType/index"
import allUsersSlice from "./createUser/index"
import myFilesSlice from  "./myFiles/index"
import superNFTSlice from "./superNFT"
import contributorsRevenueSlice from "./contributorsRevenue"
import additionalAssetsSlice from "./additionalAssets"
import KycSlice from "./kyc"
import collectionSuperNFTSlice from  "./collectionSuperNFT"
// ...

export const store = configureStore({
  reducer: {
    global: globalSlice,
    auth:authSlice,
    contributor: contributorSlice,
    assetTypes: assetTypesSlice,
    assetTags: assetTagsSlice,
    instituteType: instituteStoreSlice,
    contributorType: contributorTypeSlice,
    collectionType: collectionTypeSlice,
    allUsers: allUsersSlice,
    contributorRole: contributorRoleSlice,
    myFiles: myFilesSlice,
    superNFT: superNFTSlice,
    contributorsRevenue: contributorsRevenueSlice,
    additionalAsset: additionalAssetsSlice,
    kyc: KycSlice,
    collectionSuperNFT: collectionSuperNFTSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch