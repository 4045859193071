import { collectionSuperNFTStoreType } from "store/type";

export const initCollectionSuperNFTState: collectionSuperNFTStoreType = {
	collectionStep: 1,
	selectSuperNftModal: false,
	previewCollectionModal: null,
	allCollections: [],
	getCollectionListLoading: false,
	saveCollectionLoading: false,
	saveCollectionPreviousLoading: false,
	getCollectionLoading: false,
	formCollection: {
		title: "",
		artist_name: "",
		artist_legal_name: "",
		artist_prs_no: "",
		description: "",
		step: 0,
		tags: [],
		contentCreatorTypeId: null,
	},
	collectionDetail: {
		step: 5,
		title: "",
		artist_name: "",
		artist_legal_name: "",
		artist_prs_no: "",
		description: "",
		tags: [],
		created_at: "",
		status: "",
		updated_at: "",
		contributors: [],
	},
	collectionDetailLoading: false,
	collectionNftList: [],
	collectionNftListLoading: false,
	userCollectionNFTList: [],
	userCollectionNFTListLoading: false,
	saveNftInCollectionLoading: false,
	updateNftInCollectionLoading: false,
	deleteNftInCollectionLoading: false,
	getAllRevenueOfCollectionLoading: false,
	allRevenueOfCollection: [],
	recentCollectionsCountLoading: false,
	recentCollectionCounts: [],
	deleteCollectionLoading: false,
	collectionRecentListLoading: false,
	collectionRecentList: [],
	recentCollectionCountLoading: false,
	recentCollectionCount: []
};
